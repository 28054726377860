 hr {
    margin: 2em 0;
    border: 1px dotted $c-darkblue;
    border-width: 1px 0 0 0;
  }
body.is-blog {
  iframe:not([title="reCAPTCHA-Widget"]) {
    width: 100%;
    height: 300px;
  }
 
  .page-layout {
    &--narrow {
      max-width: 680px;
      margin: auto;
    }
    &--full {
      width: 100%;
    }
  }
  .page-template {
    &--contact_people {
    }
  }
}
.test-device-block {
  margin: 1.5em 0;
}
.wordpress-page-79 {
  .block-blog-menu {
    display: none;
  }
}
#header-nav {
  .nav-1 {
    .view-all {
      display: none;
    }
  }
}
.is-blog {
  #header-nav {
    border-bottom: 1px dotted $c-darkblue;
  }
  .col-main {
    margin-bottom: $trim;
  }
}
.wordpress-layout-fullscreen, .wordpress-index-index, .wordpress-post-view {
  #header-nav {
    border-bottom: none;
  }
}

.layout-landingpage {
  #header-nav {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }
  .footer {
    border-top: none;
  }

  .post-entry hr:first-child {
    margin-top: 0 !important;
  }
}
.wordpress-post-view {
  .main-container {
    margin-top: 0;
  }
}
.resellers.row:last-child, .people.row:last-child {
  margin-bottom: $trim * 2;
}
.wordpress-layout-col2-right, .catalogsearch-result-index {
  #header-nav {
    border-bottom: 1px dotted $c-darkblue;
    padding-bottom: $trim;
  }
}

@import "layout";
@import "sidebar";

@import "post/list",
        "post/view";

@import "page/view";

@import "modules/people",
        "modules/resellers",
        "modules/forms",
        "modules/downloads";
