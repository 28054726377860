.product-view {

    .category-name {
        text-transform: uppercase;
    }
    #ajax-sku, #hidden-ajax-sku {
        display: none;
    }

    #ajax-sku {
        margin-top: 5px;
    }

    #image-main {
        width: 120%;
        max-width: 120%;
        margin-left: -10%;
    }

    @include bp(min-width, $bp-small+1){
        .product-col-img-box, .product-information {
            width: 50%;
            box-sizing: border-box;
            padding: 0 15px;
            float: left;
        }
    } 
    @include bp(min-width, $bp-medium+1){ 
        .product-information {
            float: none;
            width: auto;
            padding: 0;
        }
        .product-col-img-box, .product-col-description, .product-col-price {
            width: 33.33%;
            box-sizing: border-box;
            padding: 0 15px;
            float: left;
        }
    }
    .product-col-description, .product-col-price {
        @include bp(min-width, $bp-small+1){ 
            padding-top: 60px;
        }
    }
    .short-description {
         @include bp(max-width, $bp-small){ 
            padding-top: 20px;
        }
    }

    .product-col-description {
        .product-options {
            label {
                padding-left: 1px;
            }
        }
    }

    .sample-request {
        margin-top: 10px;
        margin-bottom: $trim;
        @include bp(min-width, $bp-medium+1){ 
            margin-bottom: $trim-s * 2;
        }
    }
    .graduated-link {
        margin-top: 10px;
    }
    .product-essential {
        .product-name {
            color: $c-lightblue;
        }
        .product-col-img-box {
            padding-left: 0;

            .product-share {
                margin-top: $trim;
                @include bp(max-width, $bp-small){ 
                    img {
                        width: 32px;
                        height: 32px; 
                    }
                    
                }
                @include bp(min-width, $bp-small+1){ 
                    margin-bottom: $trim;
                }
                a {
                    display: inline-block;
                }
            }
        }
        .product-view-top {
            &:after {
                @include clearfix;
            }
            border-top: 1px dotted $c-darkblue;
            padding-top: $trim;

            @include bp(min-width, $bp-small+1){ 
                padding-bottom: $trim;
            }
        }
        .product-col-price {
            padding-right: 0;
            @include bp(max-width, $bp-small){ 
                    padding-bottom: $trim-s;
                    
                }
        }
        .product-options-bottom .price-box {
            display: none;
        }
        .product-options-bottom .add-to-cart .qty-wrapper {
            display: none;
        }
        p.required {
            display: none;
        }
        .product-options {
            padding-top: $trim-s;
            margin-top: $trim-s;
            border-top: 1px dotted $c-darkblue;

            dd {
                margin-bottom: 10px;
            }
        }
        .price-box .price, .price, .configurable-price-from-label {
            font-size: 40px;
            font-family: $f-stack-special;
            color: $c-text;
        }
        .price-box {
            margin: 0;
            line-height: 1;
        }
        .availability.in-stock {
            display: none;
        }
        .price-info {
            margin-bottom: 20px;
            margin-top: 20px;    
            @include bp(min-width, $bp-small+1){
                margin-bottom: 70px;
                margin-top: 0;  
            }
        }
    }
    .product-view-details {
        &:after {
            @include clearfix;
        }
        margin: $trim 0;
        margin-top: 0;
        padding: $trim-s 0;
        border-top: 1px dotted $c-darkblue;
    }
    @include bp(min-width, $bp-medium+1){
        .product-category-nav, .product-details {
            float: left;
        }
    }
    .product-view-details {
        h2, h3, .h3, h4 {
            font-size: $f-size-xl;
            padding: $trim-xs 0;
            margin-bottom: 0;
        }
        img.image-icon {
            margin-top: -3px;
            margin-right: 7px;
        }
        .product-details {
            @include bp(min-width, $bp-medium+1){
            width: 75%;
            }

            .additional-row {
                @include bp(min-width, $bp-small+1){
                    margin-right: -15px;
                }
                &:after {
                    @include clearfix;
                }
                ul {
                    padding-bottom: $trim-small;
                }

                .slideout {
                    h2, h3 {
                        cursor: pointer;
                        position: relative;
                        &:before {
                            display: block;
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 16px;
                            width: 14px;
                            height: 10px;
                            background-image: url(../images/icon-arrow-accordion-down.svg);
                            background-size: 14px 10px;
                            background-repeat: no-repeat;
                        }
                        &.open {
                            &:before {
                               background-image: url(../images/icon-arrow-accordion-up.svg); 
                            }
                        }
                    }
                    ul {
                        display: none;
                        &.open {
                            display: block;
                        }

                        li {
                            text-indent: -8px;
                            padding-left: 8px;
                            margin: 4px 0;
                           
                            &:before {
                                content: "- ";
                            }

                            &.no-dash:before {
                                content: "";
                                display: inline-block;
                                width: 9px;
                            }
                        }

                    }
                }

                .shop-list {
                    a {
                        cursor: pointer;
                    }
                }
                .download-list {
                    margin-bottom: $trim;
                }
                &.download-shop {
                   .additional-wrapper  {
                        > div {
                            border: none;
                            padding: $trim 0;
                            padding-bottom: $trim-s;
                        }
                    }

                }
                .support-block {
                    padding-top: $trim;
                    h3 {
                        margin-bottom: 10px;
                    }
                }
                .input-box {
                     @include bp(min-width, $bp-small + 1){
                        max-width: 260px;
                    }
                }
                .input-text {
                    border: 3px solid $c-text;
                    color: $c-text;
                    background: none;
                }
                .search_mini_form .search-button:before {
                    width: 27px;
                    height: 29px;   
                    background: url(../images/icon-search-darkblue.svg);
                    background-size: 24px;
                    background-repeat: no-repeat;
                }
                 ::-webkit-input-placeholder {
                        color: $c-text;
                    }

                    input:-moz-placeholder {
                        color: $c-text;
                    }


            }
            .additional-wrapper > div {
                border-bottom: 1px dotted $c-darkblue;
            }
            @include bp(min-width, $bp-small+1){
                .additional-wrapper {
                    width: 50%;
                    box-sizing: border-box;
                    padding: 0 $trim-small;
                    float: left;
                }
            }
        }
        .product-category-nav { 
            display: none;
        }
        @include bp(min-width, $bp-medium+1){
            .product-category-nav {
                display: block;
                box-sizing: border-box;
                width: 25%;
                padding-right: $trim-small;

                ul {
                    margin-bottom: $trim-xs;
                }
            }
        }
        .box-up-sell {
             border-top: 1px dotted $c-darkblue;
        }

        .box-up-sell, .box-cross-sell {
            padding: $trim-s 0;
            @include bp(min-width, $bp-small+1){
                padding: $trim $trim-small;
                border-bottom: 1px dotted $c-darkblue;
            }
            h3, h4 {
                padding-top: 0;
            }
            a:not(.product-image) {
                color: $c-text;
                border-bottom: 1px solid $c-text;
                line-height: 1.1;

                &:hover, &.active {
                    text-decoration: none;
                    color: $c-action;
                    border-color: $c-action;
                }

            }

           
        }
        .box-up-sell {
            border-bottom: 1px dotted $c-darkblue;

            @include bp(max-width, $bp-small){
                .slick-slide img {
                    width: 80%;
                    margin: auto;
                }
            }
        }

        @include bp(max-width, $bp-small){
            .box-up-sell-slider {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
        .box-up-sell-slider, .box-cross-sell-slider {
            @include bp(min-width, $bp-small+1){
                padding: 0 $trim*2;
            }

            .slick-slide {
                margin: 0 15px;
                
            }
            .slick-next, .slick-prev {
                background: #c9c9c9;
            }
            .slick-next {
                left: auto;
                right: 0;
            }
            .slick-prev {
                right: auto;
                left: 0;
            }

            .product-name { 
                margin-top: 5px;
            }

        }
        .box-cross-sell-slider { 
            &:after {
                @include clearfix;
            }
            @include bp(min-width, $bp-small+1){

                .no-slick-slide {
                    width: 50%;
                    float: left;
                    box-sizing: border-box;
                    padding: 0 15px;

                }
            }

            .no-slick-slide.slick-slide {
                padding: 0;
            }
        }


    }
}



