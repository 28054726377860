.lightbox, .samplerequest-overlay{
  display: block;
  position: fixed;
  z-index: 9999;
  background-color: rgba(0,0,0,.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity $default-transition-duration;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  &.loading{
    opacity: 1;
    pointer-events: all;

    .spinner{
      opacity: 1;
    }
  }
  &.complete{
    opacity: 1;
    pointer-events: all;

    .spinner{
      opacity: 0;
    }
    .box{
      opacity: 1;
      pointer-events: all;
      
      @include bp(min-width, $bp-small + 1) {
        transform: translate(-50%, -50%);
      }
    }
  }
  &.scroll {
    .box {
        top: 0 !important;
        @include bp(min-width, $bp-small + 1) {
          transform: translate(-50%, 0) !important;
        }
    }
  }
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 18px;
    width: 18px;
    animation: rotate 1.2s infinite linear;
    border: 3px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    opacity: 0;
  }

  @keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
  }
  .box{
    opacity: 0;
    background-color: #fff;
    margin: 60px auto;
    width: 96%;

      @include bp(min-width, $bp-small + 1) {
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -45%);
      }


     ol {
        list-style: decimal outside;
        margin-bottom: $b-margin-bottom;
        li {
            margin-left: 2em;
        }
    }




    h1, h3, .h3, h2, h2{
      font-size: $f-size-xxl;
    }

    a#request-sample {
      margin-top: $trim-s;
    }

    h2,.h2,
    h3,.h3{
      margin-bottom: $trim-s;
    }

    max-width: 690px;
    border-radius: $default-border-radius;
    pointer-events: none;

    transition-delay: $default-transition-delay;
    transition: transform $default-transition-duration,
                opacity $default-transition-duration;

    .close{
      display: block;
      width: 30px;
      height: 30px;
      padding: 0;
      border-radius: 100%;
      background-color: $c-blue;
      color: #fff;
      min-width: auto;
      position: absolute;
      right: 0;
      top: 60px;
      @include bp(min-width, $bp-small + 1) {
      top: 0;
      }
      margin-top: -15px;
      margin-right: 50px;
      //transform: rotate(45deg);
      cursor: pointer;
      background-image: url(../images/lightbox-close.svg);
      background-size: 11px;
      background-position: center; 
      background-repeat: no-repeat;
      transition: background $default-transition-duration;

      // &:before,
      // &:after{
      //   display: block;
      //   content: '';
      //   position: absolute;
      //   left: 50%;
      //   top:25%;
      //   width: 1px;
      //   height: 50%;
      //   background-color: #fff;
      // }
      // &:after{
      //   transform: rotate(90deg) translateX(-50%);
      // }

      &:hover{
        background-color: darken($c-blue, 3%);
      }
    }
    .inner{
      padding: 40px 20px 60px 20px;

      @include bp(min-width, $bp-small + 1) {
        padding: 40px 20px;
      }


      box-sizing: border-box;
      //overflow: scroll;
      //-webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar{
        display: none;
      }
      @include bp(min-width, $bp-small + 1) {
        padding: 70px 50px;
      }
    }
  }
}

body.lightbox-active{
  overflow: hidden !important;
}

.samplerequest-overlay {

  &.active {
    opacity: 1;
    pointer-events: all;
    .box {
      opacity: 1;
      pointer-events: all;
      transform: translate(-50%, -50%);
    }
  }

}
