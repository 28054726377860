.sidebar-items {
  h3 {
    font-weight: normal;
    font-size: $f-size-xl;
    color: $c-text;
    text-transform: none;
  }
  .block-recent-posts,
  .block-related-posts{
    .block-title {
      display: none;
    }
    .posts-list-item {
      margin: 0;
      padding-bottom: 1em;
       @include bp(min-width, $bp-medium + 1) {
        &:last-child {
          border-bottom: 1px dotted $c-darkblue;
        }
      }
      .featured-image {
        margin-bottom: 1em;
      }
      a {
        color: $c-text;
        text-decoration: none !important;
      }
    }
     @media (min-width: 480px) and (max-width: 770px){
        .sidebar-posts-list:after {
          @include clearfix;
        }
        .posts-list-item {
          float: left;
          width: 50%;
          display: block;
          padding: 10px;
          box-sizing: border-box;
        }
        .sidebar-posts-list {
          margin-left: -10px;
          margin-right: -10px;
        }
      }
  }
  .link-list {
    margin-bottom: 5em;

    .link-list-item {
      margin-bottom: .5em;

    }
  }
  .block {
    .block-title {
      border-bottom: 1px dotted $c-text;
    }
    &.block-blog-menu {
      .block-content {
        ul {
          margin-top: 2px;
          li.item {
            margin: 0;
            padding: 4px 0;

            a {
              color: $c-text;
              text-decoration: underline;

              &:hover, &.active {
                color: $c-blue !important;
              }
            }
          }
        }
      }
    }
  }
  form.search_mini_form {
    margin: 3em 0;
  }
}

@include bp(max-width, $bp-medium) {
  .sidebar-spacer {
    display: none;
  }
}

.support-block {
      h3{
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px dotted $c-text;
      }
      &:after {
        display: table;
        content: '';
        clear: both;
      }
      span.phone {
        font-family: $f-stack-special;
        font-size: $f-size-xxl;
        font-weight: normal;
        display: block;
        margin-bottom: $trim-s;
      }
      .left, .right {
        width: 50%;
      }
      .left {
        float: left;
      }
      .right {
        float: right;
        img {
          margin-top: -20px;
          height: auto;
        }
      }
    }

.productfinder-index-index {
  .sidebar {
    padding-top: 8px;
  }
}

.sidebar hr {
  margin: 0;
}

.sidebar p a, .sidebar-block a {
  color: $c-text;
  text-decoration: underline;
  &:hover {
    color: $c-action;
    text-decoration: underline;
  }
}

.sidebar-block {
  border-bottom: 1px dotted $c-text;
  border-top: 1px dotted $c-text;
  padding: 18px 0;
}


