form.wpcf7-form {

  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    $c-text;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
     color:     $c-text;
     opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
     color:    $c-text;
     opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color:     $c-text;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
     color:    $c-text;
  }

  div.wpcf7-response-output{
    display: none;
    margin: 1em 0;
    border: none;
    background-color: $c-red;
    color: white;
  }
  .text-right{
    text-align: right;
  }
  .ajax-loader{
    float: left;
  }
  .ajax-loader{
    display: none !important;
  }
  .inputs{
    span.wpcf7-form-control-wrap{
      input:not([type="checkbox"]),
      select{
        -webkit-appearance: none;
        width: 100%;
        box-sizing: border-box;
        background-color: $c-divider-border;
        border: none;
        padding: 0 1em;
        height: 35px;
        line-height: 35px;
        font-size: $f-size;

        &::placeholder{
          color: $c-darkblue;
          font-family: $f-stack-sans;
        }
      }
    }
    span.wpcf7-not-valid-tip{
      color: $c-red;
    }

  }
  .success{
    color: inherit;
    display: none;

    .button{
      margin-top: 1em;
    }
  }

  &.sent{
    .inputs,.wpcf7-mail-sent-ok{display: none !important;}
    .success{display: block;}
  }
}

span.wpcf7-list-item {
  margin: 0 !important;
}




.form-wrapper form {
  input,
      select{
        -webkit-appearance: none;
        width: 100%;
        box-sizing: border-box;
        background-color: $c-divider-border;
        border: none;
        padding: 0 1em;
        height: 35px;
        line-height: 35px;
        border-radius: 0;
        font-size: $f-size;

        &::placeholder{
          color: $c-darkblue;
          font-family: $f-stack-sans;
        }
      }
}