body.is-blog{
  .col2-right-layout{
    .col-main,
    .col-right{
      box-sizing: border-box;
    }
  }
  .col2-set{
    .col-1,
    .col-2{
      box-sizing: border-box;
    }
  }
}
form, .form-wrapper {

  .row{
    &:after {
      @include clearfix;
    }

      
    @include bp(min-width, $bp-small + 1) {
        
        margin-left: -10px;
        margin-right: -10px;
      }

    .col-half,
    .col-full{
      display: block;
      vertical-align: top;
      padding: 5px 0;
      width: 100%;
      @include bp(min-width, $bp-small + 1) {
        padding: 10px;
      }
      box-sizing: border-box;
      
      @include bp(max-width, $bp-small) {
        &.submit {
          float: right;
        }
      }

    }
    .col-half{
      @include bp(min-width, $bp-small + 1) {
        width: 50%;
        float: left;
      }
    }
    .col-full{
      width: 100%;
    }
  }
}

