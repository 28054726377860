.resellers{

    .col-half {
      .reseller-item {
        h2{
          
          margin-top: 0;

          @include bp(max-width, $bp-small) {
            margin-top: 20px;
          }
          
          img,span{
            display: inline-block;
            vertical-align: middle;
          }
          img{
            height: 1.2em;
            margin-right: .3em;
            width: 30px;
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;          }
        }
      }
    }
}

