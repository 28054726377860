.post-entry{
  hr{
    margin: .5em 0 !important;
    border: 1px dotted $c-darkblue;
    border-width: 1px 0 0 0;
  }
  h1,.h1,
  h2,.h2,
  h3,.h3{
    margin-top: $trim-s;
    margin-bottom: 10px;
    text-transform: none;
    font-size: $f-size-xxl;
  }
  h1,.h1 {
    margin-top: 0;
  }
  a:not(.button){
    color: $c-darkblue;
    text-decoration: underline;

    &:hover{
      color: $c-blue;
    }

    &.back {
      margin-top: $trim;
      display: block;
    }
  }
  h3,.h3 {
    font-size: $f-size-xl;
  }
  h1 {
    font-size: $f-size-xxl;
  }
  h1, h2, h3, h4, h5, h6 {
      a {
          text-decoration: none !important;

          &:hover {
            color: inherit !important;
          }
      }
  }

  &.lightbox-content-link a:not(.back) {
    text-decoration: none;
  }
  .spacer {
    height: $trim;
  }
}
.post-title{

  &.has-subtitle{
    .page-subtitle{
      margin-bottom: 0;
    }
    h1{
      color: $c-blue;
    }
    padding-bottom: 5em;
    border-bottom: 1px dotted $c-darkblue;
    margin-bottom: 1em;
  }
}

.wordpress-post-view {
  em {
    font-size: $f-size-xxl;
    font-style: normal;
    font-family: $f-stack-special;
    line-height: 1.2;
  }
}

.post-meta.details {
  margin-top: $trim;
}

.post-entry, .lightbox {

  ul {
    padding: 0;
    margin: 0;
    li {
      text-indent: -8px;
      padding-left: 8px;
      margin: 4px 0;
      list-style: none;
      &:before {
        content: "- ";
      }
    }
  }

  ol {
    padding: 0;
    margin: 0;
    li {
      margin: 4px 0;
    }
  }

}