.finder-form {
	margin: $trim 0;

	.level {
		padding-top: $trim;
		margin-top: $trim;
		border-top: 1px dotted $c-darkblue;
	}

	.select-wrapper {
		margin-bottom: 10px;
	}

	@include bp(min-width, $bp-small + 1) {

		.select-row {
			margin: 0 -5px;


			&:after {
				@include clearfix;
			}

		}

		.select-wrapper {
			float: left;
			width: 50%;

			.select-wrapper--inner {
				margin: 0 5px;
			}

		}

	}
}

#productResult {
	margin-top: $trim;
	// .product {
	// 		a.product-image {
	// 			display: block;
	// 			padding-bottom: 70%;
	// 			overflow: hidden;
	// 			img {
	// 				//position: absolute;
	// 			}
	// 		}
	// 	}
}

.block-productfinder {
	ul li.product .product-title {
		border-bottom: none;

		a {
			font-family: $f-stack-special;
			font-size: $f-size-l;
			line-height: 1.2;
			&:hover {
				text-decoration: none;
			}
		}
	}
}

#block-productfinder {
	.slick-slide {
		opacity: 0;
	}
	&.slick-initialized {
		.slick-slide {
			opacity: 1;
		}
	}
}

.finder-notice, .finder-noproduct {
	padding-top: $trim;
	margin-top: $trim;
	border-top: 1px dotted $c-darkblue;
}

.finder-noproduct { 
	display: none;
	border: none;
}

