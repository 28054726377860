.people, .resellers {
	&.row {
		margin-right: -15px;
		margin-left: -15px;
		position: relative;

		@include bp(min-width, $bp-small + 1) {
			
			display: table;
			width: 100%;
			height: 100%;
		}
		&:after {
			@include clearfix;
		}
	}
	@include bp(min-width, $bp-small + 1) {
		&.first {
			.people-item, .reseller-item {
				border-top: 1px dotted $c-darkblue;
			}
		}
	}
	.col-half {
		margin: 0 15px;

		@include bp(min-width, $bp-small + 1) {
			width: 50%;
			height: 100%;
			display: table-cell;
			padding: 0 15px;
			margin: 0;
		}
		.people-item, .reseller-item {

			img {
				width: 100%;
				@include bp(min-width, $bp-small) {
					margin-top: 20px;
				}
			}
			height: 100%;
			box-sizing: border-box;
			border-bottom: 1px dotted $c-darkblue;

			@include bp(min-width, $bp-small + 1) {
				padding-top: $trim-s;
			}
		}
		p {
			margin-top: $trim-s;
		}
	}
	&.first .col-half:first-child {
		.people-item, .reseller-item {
			border-top: 1px dotted $c-darkblue;
		}
	}
	@include bp(max-width, $bp-small) {
		&:last-child .col-half {
			.people-item, .reseller-item {
				border-bottom: 0;
			}
		}
	}
}

.resellers.row:last-child,
.people.row:last-child  {
	margin-bottom: $trim * 2;
}