.wordpress-post-view{
  .post-entry{
    overflow: visible;
    img {
      height: auto;
    }
    .featured-image .post-share img {
      width: 30px;
      height: 30px;
    } 
  }

  .home-slide-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .post-teaser{
    @include bp(min-width, $bp-medium + 1){
        width: 146.12%;
    }
    border-bottom: 1px dotted $c-darkblue;
    margin-bottom: $trim-s;
  }
  .featured-image{
    border: 0;
    padding: $trim-s 0;
    margin-bottom: $trim-s;
    border-top: 1px dotted $c-darkblue;
    border-bottom: 1px dotted $c-darkblue;
    position: relative;

    img{
      width: 100%;
    }

    .post-share {
      position: absolute;
      right: 0;
      bottom: 15px;
      img {
        width: auto;

      }
    }

  }
  .post-share{

      a{
        display: inline-block;
      }
    }
  .post-meta {
    font-style: normal;
  }
}
