body.wordpress-index-index {
  .footer {
    border: none;
  }
  .home-slide-wrapper {
    margin-left: -15px;
    margin-right: -15px; 
    @include bp(min-width, $bp-medium + 1) {
      margin-left: -25px;
      margin-right: -25px;
    }
  }
  .main-container {
    padding-top: 20px;
  }
  .page-title {
    display: none;
  }
  .featured-image {
    border: none;
    margin: 0;
  }
  .post-list{
    .home-slider-container {
        margin-bottom: $trim;
      }
    ul#post-list {
      li.item {
        margin-bottom: 0;

        &:last-child {
          border-bottom: 1px dotted $c-darkblue;
        }
        .post-list-item {
          .post-entry {
            margin-bottom: 0;
            padding-top: $trim-s;
            
            &.post-entry-with-image{
              border-top: 1px dotted $c-darkblue;
            }

            .featured-image {
              img {
                width: 100%;
                max-width: none;
              }
            }
            h2 {
              margin-top: $trim-s;
              padding-top: $trim;
              border-top: 1px dotted $c-darkblue;

              a {
                text-decoration: none;
                text-transform: none;
                letter-spacing: 0;
              }
            }
            .post-excerpt {
              margin-bottom: $trim-s;
            }
          }
        }
      }


    }
    .pager{
      margin-top: 2rem;
      margin-left: 0;
      float: none;
      display: block;
      width: 100%;
      text-align: center;

      .pages{
        display: inline-block;
        float: none;
        margin-bottom: 0;
        margin-left: 0;

        ol{
          li{
            display: inline-block;
            vertical-align: middle;
            float: none;
            width: 20px;
            height: 30px;
            color: $c-blue;

            &.current{
              border: 0;
              color: $c-darkblue;
            }

            a{

              &.next,
              &.previous{
                width: auto;
                height: auto;
                border: 0;
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
