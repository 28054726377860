/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    opacity: 0;
    transition: opacity .3s ease-out;

    &.slick-initialized {
        opacity: 1;
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    width: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
        width: 100%;
    }
    &.slick-loading img {
        display: none;
    }
    display: none;

    &.dragging img {
        pointer-events: none;
    }
    .slick-initialized & {
        display: block;
    }
    .slick-loading & {
        visibility: hidden;
    }
    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev, .slick-next {
    background: $c-text;
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    padding: 9px 0;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: -40px;
    cursor: pointer;

    img {
        display: inline-block;
        margin-left: -2px;
    }
}
.slick-next {
    left: auto;
    right: -40px;

    img {
        margin-left: auto;
        margin-right: -2px;
    }
}
.slick-dots {
    text-align: center;

    button {
        display: none;
    }
    li {
        width: 16px;
        height: 16px;
        background: #b6b6b6;
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        margin: 0 7px;
        padding: 0 !important;
        margin-top: $trim-s;

        &.slick-active {
            background: $c-action;
        }
    }
}
.slick-slide {
    outline: none
}
.wordpress-layout-fullscreen {
    .fullscreen-slider {
        padding-top: 1px;
        width: 100%;
        height: 90vh;
        position: relative;
        margin-bottom: $trim-s;

        .slick-list {
            height: 100%;

            .slick-track {
                height: 100%;
            }
        }
        .slick-next {
            right: 40px;
            left: auto;
            z-index: 10;
        }
        .slick-prev {
            left: 40px;
            right: auto;
            z-index: 10;
        }
        .slick-dots {
            position: absolute;
            bottom: $trim;
            width: 100%;

            li {
                background: white;

                &.slick-active {
                    background: $c-action;
                }
            }
        }
        .content-wrapper {
            height: 100%;
            padding-top: 10px;
        }
        .slick-slide {
            background-size: cover;

            &.slide1 {
                background-color: #AAC502;

                .headline {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-40%);
                    font-size: 40px;
                    text-transform: uppercase;
                    color: white;
                    font-family: $f-stack-special;
                    line-height: 1;

                    @include bp(min-width, $bp-small + 1) {
                        font-size: 68px;
                    }
                    @include bp(min-width, $bp-medium + 1) {
                        font-size: 88px;
                    }
                    @include bp(min-width, $bp-large + 1) {
                        font-size: 100px;
                    }
                }
            }
            &.slide2 {
                background-image: url(../images/about-slider/stock.jpg);
            }
        }
    }
}

#block-productfinder {
    .slick-prev, .slick-next {
        background: #c9c9c9;
        background-image: url(../images/arrow-left.svg);
        background-size: 12px;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 100;
        top: 30%;
    }

    .slick-prev {
        left: 15px;
    }

    .slick-next {
        background-image: url(../images/arrow-right.svg);
        right: 15px;
    }
}