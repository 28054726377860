ul.downloads{
  list-style-type: none;
  padding: 0;
  margin: 0;

  .download-item{
    position: relative;
    margin-bottom: .5em;
    margin-left: 2em;
    list-style: none;

    &--pdf{
      &:before{
        background-image: url('../images/icon-download-pdf.svg');
      }
    }
    &--zip{
      &:before{
        background-image: url('../images/icon-download-zip.svg');
      }
    }

    &:before{
      content: '';
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      margin-left: -2em;
      margin-right: 2px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 22px;
      height: 22px;
      background-image: url(../images/icon-download-pdf.svg);
      background-size: 19px 22px;
      background-repeat: no-repeat;
    }

    a{
      text-decoration: none;
      color: inherit;
    }
  }
}
